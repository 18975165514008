import $ from 'jquery'
import { SCROLLFIRE, OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset, isMobile } from './helper.js'
import { calendarProgOverlay, calendarOverlay } from './overlays.js'
import { tailSelect } from './form.js'
import LazyLoad from 'vanilla-lazyload'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })

  return animation.finished
}


// Permet de créer un calendrier
export function initCalendar(selector = '.calendar') {
  if (!document.querySelector(selector)){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector(selector).dataset.list

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')

  $(selector).datepicker({
    language: 'fr',
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    maxViewMode: 2,
    changeMonth: true,
    changeYear: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    },
  }).on('changeDate', function(e) {
    if (typeof calendarProgOverlay !== 'undefined') {
      calendarProgOverlay.close()
    }
    calendarOverlay.close()
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    let currentPath = window.location.pathname

    if (currentPath == '/programmation'){
      $.request('showsList::onChangeDate', {
        data: {date: theTimestamp},
        update: {
          'showsList::list':'#showsList',
          'showsList::pagination':'#pagination',
          'showsList::filters':'#filters',
        },
        complete: function (data) {
          data.then(function(data) {
            refreshShowsList(true)
            refreshLazyLoad()
          })
        },
      })
    }
    else if ( currentPath.includes('festivals-et-evenements') && currentPath.includes('programmation')) {
      $.request('festivalShowsListe::onChangeDate', {
        data: {date: theTimestamp},
        update: {
          'festivalShowsListe::list':'#showsList',
          'festivalShowsListe::pagination':'#pagination',
          'festivalShowsListe::filters':'#filters',
        },
        complete: function (data) {
          data.then(function(data) {
            refreshShowsList(true)
            refreshLazyLoad()
          })
        },
      })
    }
    else {
      $.request('onChangeDate', {
        data: {date: theTimestamp},
        complete: function (data) {
          data.then(function(data) {
            window.location.href = '/programmation'
          })
        },
      })
    }

    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $(selector).datepicker('update')

  initMonthsClick($(selector))

  function initMonthsClick(calendar) {
    calendar.on('changeMonth', function(e) {

      if(e.viewMode == 1) { // Si c'est un clic sur un bouton de lien, et non pas avoir cliqué sur les flèches de mois
        calendarProgOverlay.close()
        calendarOverlay.close()
        let date = e.date
        let currentYear = date.getYear()+1900
        let currentMonth = date.getMonth()+1

        let currentPath = window.location.pathname

        if (currentPath == '/programmation'){
          $.request('showsList::onChangeMonth', {
            data: {month: currentMonth + '/' + currentYear },
            update: {
              'showsList::list':'#showsList',
              'showsList::pagination':'#pagination',
              'showsList::filters':'#filters',
            },
            complete: function (data) {
              data.then(function(data) {
                refreshShowsList(true)
                refreshLazyLoad()
              })
            },
          })
        }
        else if ( currentPath.includes('festivals-et-evenements') && currentPath.includes('programmation')) {
          $.request('festivalShowsListe::onChangeMonth', {
            data: {month: currentMonth + '/' + currentYear },
            update: {
              'festivalShowsListe::list':'#showsList',
              'festivalShowsListe::pagination':'#pagination',
              'festivalShowsListe::filters':'#filters',
            },
            complete: function (data) {
              data.then(function(data) {
                refreshShowsList(true)
                refreshLazyLoad()
              })
            },
          })
        }
        else {
          $.request('onChangeMonth', {
            data: {month: currentMonth + '/' + currentYear },
            complete: function (data) {
              data.then(function(data) {
                window.location.href = '/programmation'
              })
            },
          })
        }
        return false
      }
    })
  }
}


// Copier du texte
export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// Ajoute les metas pour le contenu og
export function ogContent(data) {
  let ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
  let ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
  let ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
  let ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
  document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
  document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
  document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
  document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
}


// Permet d'aller au backend
export function backendKeyBind() {
  $('body').keydown(function (e) {
    let keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
}

export const initHomeBannerHeight = (options) => {
  var currentWidth = window.innerWidth
  document.querySelector('.js-home-banner').style.height = window.innerHeight + 'px'

  OBSERVER.add({
    name: 'homeBannerHeight',
    event: 'resize',
    target: 'window',
    function: homeBannerHeight
  })

  OBSERVER.on('homeBannerHeight')

  function homeBannerHeight(){
    if(isMobile()){
      if(!(window.innerWidth == currentWidth)) {
        currentWidth = window.innerWidth
        document.querySelector('.js-home-banner').style.height = window.innerHeight + 'px'
      }
    }else{
      currentWidth = window.innerWidth
      document.querySelector('.js-home-banner').style.height = window.innerHeight + 'px'
    }
  }

}

// Initialisation des hovers de la section Découvrir
export const followCursor = () => {
  function mouseTrack(e) {
    let rect = e.target.getBoundingClientRect()
    let x = e.clientX - rect.left //Position x à l'intérieur de l'élément.
    let y = e.clientY - rect.top  //Position y à l'intérieur de l'élément.
    let image = e.target.parentNode.querySelector('.js-follow-image')
    image.style.left = x + 'px'
    image.style.top = y + 'px'
  }

  OBSERVER.add({
    name: 'mouseTrack',
    event: 'mousemove',
    target: '.js-follow-image-container',
    function: mouseTrack
  })

  if(!isMobile()){
    OBSERVER.on('mouseTrack')
  }
}

// Fonction gérant les alertes
export function alerts() {
  //const closeAlertsBanner = e => document.querySelector('.js-alerts-banner').style.height = 0
  function closeAlertsBanner() {
    document.querySelector('.js-alerts-banner').style.height = 0
    document.querySelector('.js-alerts-banner').style.opacity = 0
  }
  OBSERVER.add({ name: 'alerts', event: 'click', function: closeAlertsBanner, target: '.js-alerts-close' })
  OBSERVER.on('alerts')
}

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')){
            var selectorText = styleSheet.rules[ri].selectorText
            var araySelectorText = selectorText.split(',')
            var newSelectorText = ''

            // Garder slm les selectorText no :hover
            for (var i = 0; i < araySelectorText.length; i++){
              if (!araySelectorText[i].match(':hover')){
                newSelectorText += (newSelectorText !== '')? ', ' + araySelectorText[i] : ' ' + araySelectorText[i]
              }
            }

            // Enlver la règle si la seule classe est un :hover
            if (newSelectorText === ''){ styleSheet.deleteRule(ri) }
            else { styleSheet.rules[ri].selectorText = newSelectorText }
          }
        }
      }
    } catch (error) { console.log(error) }
  }
}


// AJAX handlers à cause des tail.select...

export function changeNewsCategorie(categorie) {
  $.request('nouvelles::onChangeCategorie', {
    data: {categorie: categorie},
    update: {
      'nouvelles::list':'#newsList',
      'nouvelles::pagination':'#pagination',
    },
    complete: function (data) {
      data.then(function(data){
        refreshLazyLoad()
      })
    }
  })
}

export function changeShowsRoom(room) {
  $.request('showsList::onChangeRoom', {
    data: {room: room},
    update: {
      'showsList::list':'#showsList',
      'showsList::pagination':'#pagination',
    },
    complete: function (data) {
      data.then(function(data){
        refreshShowsList(false)
        refreshLazyLoad()
      })
    }
  })
}

export function changeShowsMention(mention) {
  $.request('showsList::onChangeMention', {
    data: {mention: mention},
    update: {
      'showsList::list':'#showsList',
      'showsList::pagination':'#pagination',
    },
    complete: function (data) {
      data.then(function(data) {
        refreshShowsList(false)
        refreshLazyLoad()
      })
    }
  })
}

export function changeFestivalShowsRoom(room) {
  $.request('festivalShowsListe::onChangeRoom', {
    data: {room: room},
    update: {
      'festivalShowsListe::list':'#showsList',
      'festivalShowsListe::pagination':'#pagination',
    },
    complete: function (data) {
      data.then(function(data){
        refreshShowsList(false)
      })
    }
  })
}

export function changeFestivalShowsMention(mention) {
  $.request('festivalShowsListe::onChangeMention', {
    data: {mention: mention},
    update: {
      'festivalShowsListe::list':'#showsList',
      'festivalShowsListe::pagination':'#pagination',
    },
    complete: function (data) {
      data.then(function(data) {
        refreshShowsList(false)
      })
    }
  })
}

export function changePackagesCategorie(categorie) {
  $.request('packagesList::onChangeCategorie', {
    data: {categorie: categorie},
    update: {
      'packagesList::list':'#packagesList',
    },
    complete: function (data) {
      data.then(function(data){
        refreshList()
        refreshLazyLoad()
      })
    }
  })
}

export function refreshList() {
  SCROLLFIRE.destroy()
  SCROLLFIRE.init()
  refreshLazyLoad()

  scrollToBlock({
    scrollTo: '.main',
    duration: 600,
    offset: 0
  })
}

export function refreshShowsList(refreshSelect) {

  SCROLLFIRE.destroy()

  if (refreshSelect){
    tailSelect()
  }

  calendarProgOverlay.destroy()
  $('.calendar-programmation').children().remove()
  initCalendar('.calendar-programmation')
  calendarProgOverlay.init()

  SCROLLFIRE.init()

  scrollToBlock({
    scrollTo: '.main',
    duration: 600,
    offset: 0
  })
}

export function changeResultProvider(provider) {
  $.request('rechercheResults::onChangeProvider', {
    data: {filter: provider},
    update: {
      'rechercheResults::shows':'#shows',
      'rechercheResults::results':'#results',
    },
    complete: function (data) {
      data.then(function(data){
        refreshList()
        refreshLazyLoad()
      })
    }
  })
}

export function resultsLoaded(data) {

  SCROLLFIRE.destroy()
  SCROLLFIRE.init()

  if (!data['hasMorePages']) $('#moreResultsBtn').hide()
  setTimeout(function () {
    $('html, body').animate({
      scrollTop: $('.resultpage' + data['currentPage']).offset().top - 100
    }, 600, 'easeInOutExpo', function () {})
  }, 250)
}

export function adjustBackButtonTrack() {

  let backButton = document.querySelector('.js-back-button')
  if(backButton) {
    backButtonTrackSize()

    OBSERVER.add({
      name: 'backButtonTrackSize',
      event: 'resize',
      target: 'window',
      function: backButtonTrackSize
    })
    OBSERVER.on('backButtonTrackSize')

    function backButtonTrackSize() {
      // Vérifier si il y a un haut de page avec image
      let pageTop = document.querySelector('.page-top--with-image') !== null
      let elDistanceToTop

      if(pageTop) {
        let el = document.querySelector('.js-page-top-white-square')
        elDistanceToTop = (window.pageYOffset + el.getBoundingClientRect().top) - (document.querySelector('.js-header').offsetHeight)
      } else {
        elDistanceToTop = document.querySelector('.page-top').offsetHeight
      }

      document.querySelector('.js-back-button').style.top = elDistanceToTop + 'px'
    }
  }
}


export function refreshLazyLoad(){
  window['lazyload'] = new LazyLoad()
  SCROLLFIRE.init()
}


// Fonction permettant de donner le focus au champ newsletter
export function focusNesletterField() {
  let inputNewsletter = document.querySelector('.js-newsletter-input')

  clickToScrollToBlock({ selector: '.js-scroll-to-form-newsletter', scrollTo: '.js-form-newsletter', duration: 800 })
  const focusInput = () => { setTimeout(function(){ inputNewsletter.focus() }, 800)}

  OBSERVER.add({ name: 'focusInput',  event: 'click',  target: '.js-scroll-to-form-newsletter',  function: focusInput })
  OBSERVER.on('focusInput')
}


// Fonction Permettant de fermer toute les accordions ouvert du menu filtre mobile
export function closeAllAccordionsMobileFilter() {
  const containerClass  = 'js-accordions-filters-level-1'
  const accordionClass  = 'js-accordion-filters-level-1'
  const openedClass     = 'js-accordion-filters-opened-level-1'
  const contentClass    = 'js-accordion-filters-content-level-1'
  const easing          = 'easeInOutQuart'
  const duration        = 700
  const delay           = 800

  const accordionElements       = document.querySelectorAll(`.${containerClass} .${accordionClass}.${openedClass}`)
  const accordionContents       = document.querySelectorAll(`.${containerClass} .${accordionClass}.${openedClass} .${contentClass}`)
  const accordionElementsLength = accordionElements.length


  setTimeout(function(){ closeAllAccordions() }, delay)

  function closeAllAccordions(){
    for (let i=0; i<accordionElementsLength; i++) {
      accordionElements[i].classList.remove(openedClass)

      let childrenHeight = 0
      let childrenLength = accordionElements[i].querySelector(`.${contentClass}`).children.length

      for (let j=0; j<childrenLength; j++)
        childrenHeight += accordionElements[i].querySelector(`.${contentClass}`).children[j].clientHeight

      accordionContents[i].style.height = `${childrenHeight}px`

      anime({
        targets: accordionContents[i],
        height: '0px',
        easing: easing,
        duration: duration
      })
    }
  }
}

export function contextualConsent() {
  if (window._axcb) {
    (_axcb = window._axcb || []).push(function(sdk) {
      sdk.on('cookies:complete', function(choices){
        document
          .querySelectorAll('[data-hide-on-vendor-consent]')
          .forEach(el => {
            const vendor = el.getAttribute('data-hide-on-vendor-consent')
            el.style.display = choices[vendor] ? 'none' : 'inherit'
          })
        document
          .querySelectorAll('[data-requires-vendor-consent]')
          .forEach(el => {
            const vendor = el.getAttribute('data-requires-vendor-consent')
            el.style.display = choices[vendor] ? 'inherit' : 'none'
          })
      })
    })
  }
}
