
// |--------------------------------------------------------------------------
// | Sliders
// |--------------------------------------------------------------------------
// |
// | Création de sliders.
// |--------------------------------------------------------------------------


// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import Swiper from 'swiper/bundle'
import { Accordions } from '../classes/Accordions'
import { OBSERVER } from '../main'

export const homeBanner = () => {

  document.querySelector('.js-home-banner').style.height = window.innerHeight + 'px'


  // Looper dans chaques slides et réduire le titre si il est plus long que 30 caractères
  let homeSlides = document.querySelectorAll('.js-home-banner-title');
  [].forEach.call(homeSlides, function(slide) {
    if(slide.innerHTML.length > 18) {
      slide.classList.add('home-banner__title--smaller')
    }
  })

  // Réduire la largeur de la section thumbs si on a 2 slides ou moins
  const count = document.querySelectorAll('.js-home-banner-thumbs-slides .swiper-wrapper .swiper-slide').length;
  if(count <= 2) {
    document.querySelector('.js-home-banner').style.setProperty('--thumbs-width','310px');
  }

  // Slider des images
  let homeBannerSlider = new Swiper('.js-home-banner-slides', {
    slidesPerView: 1,
    effect: 'fade',
    loop: true,
    loopedSlides: 3,
    autoplay: {
      delay: 5000,
    },
  })

  // Slider des thumbs
  let homeBannerThumbsSlider = new Swiper('.js-home-banner-thumbs-slides', {
    spaceBetween: 40,
    slidesPerView: 'auto',
    loop: true,
    loopedSlides: 3,
    slideToClickedSlide: true,
    on: {
      init: function (e) {
        e.el.parentNode.classList.add('js-slider-inited')
      },
    },
  })

  // Synchronisation des thumbs et images
  homeBannerSlider.controller.control = [homeBannerThumbsSlider]
  homeBannerThumbsSlider.controller.control = [homeBannerSlider]

}

export const swiperFeatured = () => {
  let homeFeaturedSlider = new Swiper('.js-featured-slides', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 30,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      prevEl: '.js-featured-slides-prev',
      nextEl: '.js-featured-slides-next',
    },
    breakpoints: {
      768: {
        spaceBetween: 50,
      },
    },
  })
}

export const swiperLogos = () => {
  let logoSlider = new Swiper('.js-logos-slides', {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: false,
    autoplay: {
      delay: 3000,
    },
    breakpoints: {
      440: {
        slidesPerView: 4,
      },
      1100: {
        slidesPerView: 5,
      },
      1300: {
        slidesPerView: 6,
      },
      1400: {
        slidesPerView: 7,
      },
    },
  })  
}
  