import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, removeHoverOnMobile, initCalendar, ogContent, clickToScrollToBlock, initHomeBannerHeight, followCursor, alerts, changeNewsCategorie, refreshList, refreshShowsList, changeShowsRoom, changeShowsMention, changePackagesCategorie, resultsLoaded, changeResultProvider, changeFestivalShowsMention, changeFestivalShowsRoom, adjustBackButtonTrack, refreshLazyLoad, focusNesletterField, closeAllAccordionsMobileFilter, contextualConsent } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, clearInput } from './functions/form.js'
import { homeBanner, swiperFeatured, swiperLogos } from './functions/sliders.js'
import { overlayMenu, overlaySearch, overlayCalendar, overlayCalendarProgrammation, overlayPopup, overlayFilters, overlayShare  } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formExample, formSuccess, formJobs, formContact, formInfolettre } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { sizeTitle } from './functions/helper'

window.barba = barba
window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.changeNewsCategorie = changeNewsCategorie
window.changeShowsRoom = changeShowsRoom
window.changeShowsMention = changeShowsMention
window.changeFestivalShowsRoom = changeFestivalShowsRoom
window.changeFestivalShowsMention = changeFestivalShowsMention
window.refreshList = refreshList
window.refreshShowsList = refreshShowsList
window.changePackagesCategorie = changePackagesCategorie
window.resultsLoaded = resultsLoaded
window.changeResultProvider = changeResultProvider
window.refreshLazyLoad = refreshLazyLoad
window.closeAllAccordionsMobileFilter = closeAllAccordionsMobileFilter

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    overlayShare()
    overlayMenu()
    overlaySearch()
    overlayCalendar()
    overlayPopup()

    alerts()

    clearInput()

    backendKeyBind()
    removeHoverOnMobile()

    swiperLogos()

    formInfolettre('#form-newsletter', '#form-newsletter-submit')

    // Back to top
    clickToScrollToBlock({ selector: '.js-scroll-to-header', scrollTo: 'body', offset: 0 })
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.after = (() => {
    gtag('event', 'page_view', { 'page_path': location.pathname + location.search })
  })

  barba.hooks.afterEnter(() => {
    if(document.querySelector('.fileField')) {
      if (typeof $('[data-control=multi-file-uploader]').multiFileUploader == 'function') {
        $('[data-control=multi-file-uploader]').multiFileUploader()
      }
    }
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'homeView',
        beforeEnter() {
          initHomeBannerHeight()
          homeBanner()
          swiperFeatured()
          followCursor()
          focusNesletterField()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          formExample()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          formJobs()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          formContact()
          clickToScrollToBlock({ selector: '.js-scroll-to-form-contact', scrollTo: '.js-form-contact', offset: -50 })

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'showView',
        beforeEnter() {
          new Accordions()
          dynamicPackage()

          // Liens dans barre sticky
          clickToScrollToBlock({ selector: '.js-scroll-to-informations', scrollTo: '.js-informations', offset: 0 })
          clickToScrollToBlock({ selector: '.js-scroll-to-description', scrollTo: '.js-description', offset: -50 })
          clickToScrollToBlock({ selector: '.js-scroll-to-medias', scrollTo: '.js-medias', offset: 0 })
          clickToScrollToBlock({ selector: '.js-scroll-to-similar', scrollTo: '.js-similar', offset: -50 })

          contextualConsent()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'packagesView',
        beforeEnter() {
          formsPackage()
          overlayFilters()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'packageView',
        beforeEnter() {
          dynamicPackage()
          adjustBackButtonTrack()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'actualitesView',
        beforeEnter() {
          formsPackage()
          overlayFilters()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'actualiteView',
        beforeEnter() {
          dynamicPackage()
          adjustBackButtonTrack()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'promoView',
        beforeEnter() {
          new Accordions()
          dynamicPackage()
          formJobs()
          clickToScrollToBlock({ selector: '.js-scroll-to-content', scrollTo: '.js-content', offset: 0 })
          clickToScrollToBlock({ selector: '.js-scroll-to-form', scrollTo: '.js-form', offset: -50 })
          sizeTitle({ selectors: 'h1.promo-banner__title', caract01: '40', caract02: '60'})

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'searchView',
        beforeEnter() {
          formsPackage()
          overlayFilters()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'programmingView',
        beforeEnter() {
          formsPackage()
          overlayFilters()
          overlayCalendarProgrammation()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        beforeLeave() {
          localStorage.setItem('scrollPositionShows', window.scrollY)
        },
        afterLeave() {}
      },
      {
        namespace: 'programmingFestivalView',
        beforeEnter() {
          formsPackage()
          overlayFilters()
          overlayCalendarProgrammation()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'sectionView',
        beforeEnter() {
          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'jobView',
        beforeEnter() {
          dynamicPackage()
          formsPackage()
          masksPackage()
          formJobs()
          adjustBackButtonTrack()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
      {
        namespace: 'partnersView',
        beforeEnter() {
          dynamicPackage()

          SCROLLFIRE.init()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave() {}
      },
    ]
  })
}
