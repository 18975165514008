
import $ from 'jquery'
import Overlay from '../vendor/overlay.js'
import { copyTextToClipboard, initCalendar } from './functions.js'
import { OBSERVER } from '../main.js'
import { Accordions } from '../classes/accordions.js'
import anime from 'animejs/lib/anime.es.js'

let calendarProgOverlay, calendarOverlay

export {calendarProgOverlay, calendarOverlay}

// Fonction gérant l'overlay Menu
export const overlayMenu = (root = document) => {
  let menu = new Overlay({
    name: 'menu',
    click: { buttons: { toggle: '.js-toggle-overlay-menu', switch: '.js-toggle-overlay-search,.js-toggle-overlay-calendar', close: '.js-close-overlay-menu' }},
    animations: {
      selector: '.js-menu-container',
      styles: [{ property: 'left', value: '0%', easing: 'easeInOutQuart' }]
    },
    events: {
      openComplete: true,
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: root,
      closeOnResize: false
    }
  })
  
  menu.init()

  const level1 = new Accordions({
    containerClass: 'js-accordions-menu-level-1',
    accordionClass: 'js-accordion-menu-level-1',
    toggleButtonClass: 'js-accordion-menu-toggle-level-1',
    contentClass: 'js-accordion-menu-content-level-1',
    openedClass: 'js-accordion-menu-opened-level-1',
  })

  const onClose = () => {
    level1.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })

  OBSERVER.on('overlayMenu')
}


// Fonction gérant l'overlay Search
export const overlaySearch = () => {
  let search = new Overlay({
    name: 'search',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-toggle-overlay-calendar, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-search' }},
    options: {
      speed: 800,
      closeOnResize: false, // Empêche l'overlay de se fermer tout seul en mobile lorsque le clavier du mobile s'ouvre
    }
  })
  
  const onOpenComplete = () => { searchInput.focus() }

  search.init()

  const searchInput = document.querySelector('.js-input-to-clear')
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onOpenComplete })
  OBSERVER.on('overlaySearch')

  activateWithKeyUp('#form-search #form-search-input')

  //Autocomplete search
  function activateWithKeyUp(input, selector) {

    var ajaxRequest = false

    $(input).keyup(function(e) {

      if( ajaxRequest ) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort()
      }

      var keycode = (e.keyCode ? e.keyCode : e.which)

      if ( keycode != 13 ) {
        if ($(this).val().length >= 4) {

          ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
            data: { q: $(this).val() },
            update: { 'rechercheAutocomplete::autocomplete':'#overlay-search .listResult'},
            complete: function (data) {
              // Results loaded.
              ajaxRequest = false

              $('.listResult').stop(true, false)
              if ($('.listResult').height() == 0 ) 
                $('.listResult').hide().css('height', '')
              
              $('.listResult').animate({ height: 'show' }, { duration: 375, specialEasing: { height: 'easeInOutQuart' }})

            },
          })
        }
        else {
          $('.listResult').stop(true, false).animate({ height: 'hide' }, { duration: 375, specialEasing: { height: 'easeInOutQuart' }, complete: function(){
            // S'assurer de pas avoir un height:0px qui apparait suite a des frappes rapides.
            $('.listResult').css('height','')
          }})
        }
      }
    })
  }
}


// Fonction gérant l'overlay Calendrier
export const overlayCalendar = () => {
  calendarOverlay = new Overlay({
    name: 'calendar',
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-calendar', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-calendar' }},
    options: {
      speed: 800,
      closeOnResize: false,
    }
  })

  initCalendar()
  calendarOverlay.init()
}

// Fonction gérant l'overlay Calendrier de filtre de la programmation
export const overlayCalendarProgrammation = () => {
  calendarProgOverlay = new Overlay({
    name: 'calendar-programmation',
    create: { close: false },
    events: { closeComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-calendar-programmation', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-calendar-programmation' }},
    options: {
      speed: 800,
      closeOnResize: false,
    }
  })

  initCalendar('.calendar-programmation')
  calendarProgOverlay.init()

  function onCloseComplete(){
    if(document.querySelector('html').classList.contains('show-overlay-filters')){
      document.querySelector('html').classList.add('disabled-page-scroll', 'show-overlay', 'show-overlay-filters')
    }
  }

  OBSERVER.add({name: 'overlayCalendarProgrammation', event: 'onCloseCompleteOverlayCalendar-programmation', function: onCloseComplete})
  OBSERVER.on('overlayCalendarProgrammation')
}

// Fonction gérant l'overlay des filtres en mobile
export const overlayFilters = () => {
  let filters = new Overlay({
    name: 'filters',
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-filters', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-filters' }},
    options: {
      speed: 800,
      closeOnResize: false,
    }
  })

  const filtersAccordion = new Accordions({
    containerClass: 'js-accordions-filters-level-1',
    accordionClass: 'js-accordion-filters-level-1',
    toggleButtonClass: 'js-accordion-filters-toggle-level-1',
    contentClass: 'js-accordion-filters-content-level-1',
    openedClass: 'js-accordion-filters-opened-level-1',
    closeAllBeforeOpen: false,
  })

  // Bouton refresh -----------------------------------------------
  const onRefresh = () => {
    let form = document.querySelector('.js-filter-form')
    form.reset()
    onChangeValue()
  }
  OBSERVER.add({
    target: '.js-filter-refresh',
    name: 'refreshFilter',
    event: 'click',
    function: onRefresh
  })
  OBSERVER.on('refreshFilter')


  // Changement des filtres ---------------------------------------
  const onChangeValue = () => {
    let form = document.querySelector('.js-filter-form')
    let filtersCount = 0

    let series = form.querySelector('.js-form-input[name="series"]:checked')
    let venues = form.querySelector('.js-form-input[name="venues"]:checked')
    let categories = form.querySelector('.js-form-input[name="categorie"]:checked')
    let filters = form.querySelector('.js-form-input[name="filter"]:checked')

    if ( typeof(series) != 'undefined' && series != null ) {
      if(series.value != '') filtersCount++
    }
    if ( typeof(venues) != 'undefined' && venues != null ) {
      if(venues.value != '') filtersCount++
    }
    if ( typeof(categories) != 'undefined' && categories != null ) {
      if(categories.value != '') filtersCount++
    }
    if ( typeof(filters) != 'undefined' && filters != null ) {
      if(filters.value != '' && filters.value != 'all') filtersCount++
    }

    document.querySelector('.js-filters-bar-number-bar').innerHTML = filtersCount
  }
  OBSERVER.add({
    target: '.js-form-input',
    name: 'changeFilter',
    event: 'change',
    function: onChangeValue
  })
  OBSERVER.on('changeFilter')

  // Initialiser l'overlay ----------------------------------------
  filters.init()
}


// Fonction gérant l'overlay de recherche
export function overlayShare() {
  let siteName = window.config.site_name

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  }).init()

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  $('.js-copy-share').on('click', function() {
    let link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $('.js-share').on('click', function(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#messengerShareBtn').attr('data-url', window.location.href)
  })

  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#messengerShareBtn').attr('data-url', window.location.href)
  })
}

// Fonction gérant le popup
export function overlayPopup() {
  let popup = new Overlay({
    name: 'popup',
    timeout: {
      delay: 2500,
    },
    events: {
      closeComplete: true
    },
    click: {
      buttons: {
        close: '.js-close-overlay-popup'
      }
    },
    options: {
      speed: 800,
      disabledPageScroll: false,
      closeOnResize: false,
    }
  })

  // Initialiser le popup seulement si il y en a un à afficher
  let popupExists = document.querySelector('.js-overlay-popup')
  if(popupExists){
    popup.init()

    const togglePopup = (e) => {
      e.target.parentNode.classList.toggle('popup__box--open')

      let targetHeight = 0
      // Voir si on ouvre ou ferme le panneau
      if(e.target.parentNode.classList.contains('popup__box--open')) {
        targetHeight = document.querySelector('.js-overlay-popup-content-wrapper').offsetHeight+'px'
      }

      anime({
        targets: document.querySelector('.js-overlay-popup-content'),
        height: targetHeight,
        easing: 'easeInOutQuart',
        duration: 300,
      })
    }
  
    OBSERVER.add({
      name: 'openPopup',
      event: 'click',
      target: '.js-toggle-overlay-popup-mobile',
      function: togglePopup
    })
  
    OBSERVER.on('openPopup')

    // Enlever le padding dans le footer quand popup disparait
    const onCloseComplete = () => { document.querySelector(':root').style.setProperty('--popup-bar-height', '0px') }

    OBSERVER.add({ name:'onCloseCompleteOverlayPopup', event: 'onCloseCompleteOverlayPopup', function: onCloseComplete })
    OBSERVER.on('onCloseCompleteOverlayPopup')
  }
  
}